
import { hubUrl } from './config'
import axios from 'axios'

export const createOrder = (userId, order) => {
    return axios({
        method: 'post',
        baseURL: hubUrl,
        url: `unauth/orders?userId=${userId}`,
        data: order
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error("Some problem occured submitting print. Please contact try again")
        }
    })
}

export function checkoutOrder(userId, orderId, rpPaymentId, rpOrderId, rpSignature) {
    return axios({
        method: 'get',
        baseURL: hubUrl,
        url: `unauth/orders/${orderId}/checkout?userId=${userId}&rpPaymentId=${rpPaymentId}&rpOrderId=${rpOrderId}&rpSignature=${rpSignature}`
    }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error("Some problem occured launching. Please contact customer support.")
        }
    });
}


export function getPrintJob(id) {
    let payload = {
        url: `print-jobs/${id}`,
        baseURL: hubUrl,
        method: 'get'
    }

    return axios(payload).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error("Some problem getting job details.")
        }
    });
}