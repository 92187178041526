
import { hubUrl } from './config'
import axios from 'axios'

export const getPrintHubs = () => {
    let payload = {
        url: 'print-hubs',
        baseURL: hubUrl,
        method: 'get'
    }

    return axios(payload);
}

export const printerStatus = (alias) => {
    let payload = {
        url: `print-hub/by-alias/printer/${alias}/status`,
        baseURL: hubUrl,
        method: 'get'
    }

    return axios(payload);
}