/* eslint no-undef: "off"*/

import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";
import { v4 as uuid } from 'uuid'
import { uploadDocument } from './DocumentService'
import { getPrintHubs, printerStatus } from './HubAndJobService'
import { createOrder, checkoutOrder, getPrintJob } from './OrderService'
import { razorpayKeyId, razorpayCompanyName } from './config'

export default class MainSlider extends Component {

  ERROR = "ERROR"
  SUCCESS = "SUCCESS"
  PENDING = "PENDING"
  PROCESSING = "PROCESSING"

  constructor(props) {
    super(props)

    let userId = localStorage.getItem("userId")
    if (!userId) {
      userId = uuid()
      localStorage.setItem("userId", userId)
    }

    this.state = {
      selectedPrinter: { alias: "" },
      selectedPrinHub: {},
      printerStatus: { loading: false},
      selectedPrintType: "",
      noOfCopies: 1,
      userId,
      availablePrinters: [],
      availablePrintHubs: [],
      documentUploadLoading: false,
      documentInfo: {},
      orderMessage: { status: this.PENDING }  // Types: ERROR, SUCCESS, PENDING, PROCESSING
    }

    this.settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      swipe: false,
      arrows: false,
      accessibility: false,
      style: { height: "100%" },
      prevArrow: <div />,
      nextArrow: <div />,
      appendDots: dots => (
        <div
          style={{
            padding: "10px"
          }}
        >
          {/* <div className="center-yellow-text">Steps</div> */}
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
          style={{
            width: "100px",
            color: "yellow",
            border: "1px yellow solid"
          }}
        >
          {this.pagingFor(i)}
        </div>
      )
    };

    this.printTypeMap = {
      "bw": "Black & White",
      "color": "Color"
    }
  }

  pagingFor(page) {
    switch(page) {
      case 0:
        return "1.Document";
      case 1:
        return "2.Printer";
      case 2:
        return "3.Type";
      case 3:
        return "4.Copies";
      case 4:
        return "5.Confirm";
      case 5:
        return "6.Print";
    }
  }

  componentDidMount() {
    getPrintHubs()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          let printHubs = response.data;
          let availablePrinters = []

          printHubs.forEach((hub) => {
            hub.printers.forEach(printer => {
              availablePrinters.push({
                hubAlias: hub.alias,
                alias: printer.alias,
                address: hub.address,
                cost: printer.cost
              });
            });
          });

          this.setState({ ...this.state, availablePrinters, availablePrintHubs: printHubs });
        } else {
          alert("Some error occured, please contact support.");
          console.log(JSON.stringify(response))
        }
      }).catch(console.log)
  }

  selectPrinter(alias) {
    let selectedPrinter = {}
    this.state.availablePrinters.forEach((printer) => {
      if (printer.alias === alias)
        selectedPrinter = printer;
    })

    let selectedPrintHub = {}
    this.state.availablePrintHubs.forEach((hub) => {
      hub.printers.forEach((printer) => {
        if (printer.alias === alias)
          selectedPrintHub = hub;
      })
    })

    this.setState({ ...this.state, selectedPrinter, selectedPrintHub, printerStatus: { loading: true } })
    printerStatus(alias)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({ ...this.state, printerStatus: {loading: false, status: response.data} })
        } else {
          console.error("Unable to load printer status")
          this.setState({ ...this.state, printerStatus: {loading: false} })
        }
      }).catch(x => {
        this.setState({ ...this.state, printerStatus: {loading: false} })
        console.error(x)
      })
  }

  selectPrintType(type) {
    this.setState({ ...this.state, selectedPrintType: type })
    this.slider.slickNext();
  }

  fileSelected(fileSelected) {
    let file = fileSelected[0]
    let fileName = file.name
    let fileType = fileName.split(".")[fileName.split(".").length - 1]

    if (fileType.toLowerCase() === "pdf") {
      this.setState({ ...this.state, documentUploadLoading: true })

      uploadDocument(this.state.userId, fileSelected[0])
        .then((response) => {
          this.setState({
            ...this.state, selectedFileName: fileSelected[0].name,
            documentInfo: response.data, documentUploadLoading: false
          })
          this.slider.slickNext();
        })
        .catch((error) => {
          this.setState({ ...this.state, documentUploadLoading: false })
          console.error(error)
          alert("Oops! Failed to upload document. Please try again.")
        })
    } else {
      alert("Only PDF files are supported at this time.")
    }
  }

  setNoOfCopies(noOfCopies) {
    if (noOfCopies > 0)
      this.setState({ ...this.state, noOfCopies: noOfCopies })
  }

  jobCheckerFunction(mainSliderRef) {
    if (mainSliderRef.state.checkedoutOrder && mainSliderRef.state.checkedoutOrder.printJob) {
      let jobId = mainSliderRef.state.checkedoutOrder.printJob.id
      getPrintJob(jobId)
        .then((jobDetails) => {
          let status = jobDetails.epsonStatus?.status?.toLowerCase()

          if (!status) {
            mainSliderRef.setState({
              ...mainSliderRef.state, orderMessage: {
                status: mainSliderRef.ERROR,
                message: "Error getting printout details"
              }
            });
            clearInterval(mainSliderRef.jobChecker)
          } else if (status === "completed") {
            mainSliderRef.setState({
              ...mainSliderRef.state, orderMessage: {
                status: mainSliderRef.SUCCESS,
                message: "Payload dropped!"
              }
            });
            clearInterval(mainSliderRef.jobChecker)
          } else if (status === "canceled") {
            mainSliderRef.setState({
              ...mainSliderRef.state, orderMessage: {
                status: mainSliderRef.ERROR,
                message: "Job was cancelled"
              }
            });
            clearInterval(mainSliderRef.jobChecker)
          } else {
            mainSliderRef.setState({
              ...mainSliderRef.state, orderMessage: {
                status: mainSliderRef.PROCESSING,
                message: `Hold tight!! Rocket about to launch! (Print Job status: ${status})`
              }
            });
          }
        })
    } else if (mainSliderRef.jobChecker) {
      clearInterval(mainSliderRef.jobChecker)
    }
  }

  payAndPrint() {
    this.slider.slickNext();
    this.setState({ ...this.state, orderMessage: { status: this.PROCESSING, message: "Stationing Rocket." } })

    let order = {
      selectedType: this.state.selectedPrintType,
      noOfCopies: this.state.noOfCopies,
      printerAlias: this.state.selectedPrinter.alias,
      documentId: this.state.documentInfo.id
    }

    let mainSliderRef = this

    createOrder(this.state.userId, order)
      .then((orderData) => {
        const options = {
          key: razorpayKeyId,
          amount: orderData.payment.amount,
          currency: orderData.payment.currency,
          name: razorpayCompanyName,
          prefill: { contact: "", email: "" },
          description: "Print cost",
          order_id: orderData.payment.id,
          handler: function (response) {
            console.log("payment successful")
            console.log(response)

            mainSliderRef.setState({
              ...mainSliderRef.state, orderMessage: {
                status: mainSliderRef.PROCESSING,
                message: "We got your money!! Fueling rocket!"
              }
            })

            let rpPaymentId = response.razorpay_payment_id
            let rpOrderId = response.razorpay_order_id
            let rpSignature = response.razorpay_signature

            checkoutOrder(mainSliderRef.state.userId, orderData.id, rpPaymentId, rpOrderId, rpSignature)
              .then((response) => {
                console.log(response)

                mainSliderRef.setState({
                  ...mainSliderRef.state, orderMessage: {
                    status: mainSliderRef.PROCESSING,
                    message: "Hold tight!! Rocket about to launch!"
                  },
                  checkedoutOrder: response
                });

                mainSliderRef.jobChecker = setInterval(() => mainSliderRef.jobCheckerFunction(mainSliderRef), 5000);
              }).catch((error) => {
                console.log(error);
                mainSliderRef.setState({
                  ...mainSliderRef.state, orderMessage: {
                    status: mainSliderRef.ERROR,
                    message: error.message
                  }
                })
              });
          }
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.error("Payment failed");
          console.log(response.error);
          console.log("payment options");
          console.log(options);
          mainSliderRef.setState({ ...mainSliderRef.state, orderMessage: { status: mainSliderRef.ERROR, message: "Payment failed. Please try again." } })
        });
        rzp1.open();
      }).catch((error) => {
        console.log(error)
        mainSliderRef.setState({ ...mainSliderRef.state, orderMessage: { status: mainSliderRef.ERROR, message: error.message } })
      })
  }

  trimmedFileName(fileName) {
    const longestWordLength = 15
    let words = fileName.split(" ")
    let final = ""
    words.forEach(word => {
      if (word.length > longestWordLength) {
        word = word.substring(0, longestWordLength - 3) + "... "
      }
      final = final + word + " ";
    })
    return final.trim()
  }

  render() {
    let printersDetails = this.state.availablePrinters;

    let costPerPage
    this.state.selectedPrinter.cost?.forEach(c => {
      if (c.colorType === this.state.selectedPrintType)
        costPerPage = c.costPerPage
    })

    const totalCost = this.state.documentInfo.pageCount * this.state.noOfCopies * costPerPage

    return (
      <div style={{ height: "100%" }}>
        <Row>
          <Col></Col>
          <Col>
            <h1>EzCopies</h1>
          </Col>
          <Col></Col>
        </Row>
        <Row style={{ height: "100%" }}>
          <Slider ref={c => (this.slider = c)} {...this.settings}>
            <div>
              <Container className="vertical-center">
                <Row>
                  <Row style={{ height: "50px", textAlign: "center" }}>
                    <div>
                      <h2>Upload Document</h2>
                    </div>
                  </Row>
                  <Row style={{ height: "80%", textAlign: "center" }}>
                    <Form.Group>
                      <Form.Control type="file"
                        accept="application/pdf"
                        ref={(input) => this.uploadFileButton = input}
                        onChange={() => this.fileSelected(this.uploadFileButton.files)}
                      />
                    </Form.Group>
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <div className="info-title">Note: Only PDF file supported at this time</div>
                  </Row>
                  {this.state.documentUploadLoading ?
                    <Row style={{ paddingTop: "30px", color: "yellow" }}>
                      <Col></Col>
                      <Col>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Col>
                      <Col></Col>
                    </Row>
                    : <Row></Row>
                  }
                </Row>
              </Container>
            </div>
            <div>
              <Container className="vertical-center">
                <Row>
                  <Row style={{ height: "50px", textAlign: "center" }}>
                    <div>
                      <h2>Printer</h2>
                    </div>
                  </Row>
                  <Row>
                    {this.state.selectedPrinter && this.state.selectedPrinter.alias ?
                      <div>
                        <Row>
                          <h2 className="center-yellow-text">{this.state.selectedPrinter.alias}</h2>
                          <div className="center-yellow-text">{this.state.selectedPrintHub.address.streetAddress}</div>
                          <div className="center-yellow-text">{this.state.selectedPrintHub.address.city}</div>
                          <div className="center-yellow-text">{this.state.selectedPrintHub.address.state}</div>
                        </Row>
                        <Row>
                          <Button variant="outline-success" onClick={() => this.setState({ ...this.state, selectedPrinter: {} })}>Change Printer</Button>
                        </Row>
                        {this.state.printerStatus.loading ? 
                        <div className="next-button center-yellow-text">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading status.....</span>
                          </Spinner>
                        </div> : 
                          this.state.printerStatus.status?.connectionStatus === "ONLINE" ?
                          <Row className="next-button">
                            <div className="center-yellow-text">
                              Please confirm you are at the above address before you submit your print
                            </div>
                            <Button variant="success" size="lg" onClick={() => this.slider.slickNext()}>
                              I am Here
                            </Button>
                          </Row> :
                          <div className="next-button center-yellow-text">
                            Sorry the printer is not available at this time. Please try again later.
                          </div>
                        }
                      </div>
                      :
                      <ButtonGroup vertical>
                        {
                          printersDetails.map(printerDetails => {
                            return (
                              <Button variant="outline-success" size="lg"
                                key={printerDetails.alias}
                                active={printerDetails.alias === this.state.selectedPrinter.alias}
                                onClick={() => this.selectPrinter(printerDetails.alias)}
                              >
                                {printerDetails.alias}
                              </Button>)
                          })
                        }
                      </ButtonGroup>
                    }
                  </Row>
                </Row>
              </Container>
            </div>
            <div>
              <Container className="vertical-center">
                <Row>
                  <Row style={{ height: "50px", textAlign: "center" }}>
                    <div>
                      <h2>{this.state.selectedPrinter && this.state.selectedPrinter.cost ?
                        "Print Type" : "Please select a printer first"}</h2>
                    </div>
                  </Row>
                  <Row>
                    <ButtonGroup vertical>
                      {
                        this.state.selectedPrinter && this.state.selectedPrinter.cost ? this.state.selectedPrinter.cost.map(c => {
                          return (

                            <Button variant="outline-success" size="lg"
                              active={c.colorType === this.state.selectedPrintType}
                              onClick={() => this.selectPrintType(c.colorType)}>
                              {this.printTypeMap[c.colorType]} - Rs{c.costPerPage}
                            </Button>

                          )
                        }) : <div />
                      }
                    </ButtonGroup>
                  </Row>
                </Row>
              </Container>
            </div>
            <div>
              <Container className="vertical-center">
                <Row>
                  <Row style={{ height: "50px", textAlign: "center" }}>
                    <div>
                      <h2>How Many Copies?</h2>
                    </div>
                  </Row>
                  <Row>
                    <InputGroup>
                      <Button variant="outline-success" size="lg"
                        onClick={() => this.setNoOfCopies(parseInt(this.state.noOfCopies) - 1)}>-</Button>
                      <FormControl
                        placeholder="No. of Copies"
                        aria-label="Recipient's username with two button addons"
                        style={{ textAlign: "center", textSize: "16px" }}
                        value={this.state.noOfCopies}
                        onChange={(e) => this.setNoOfCopies(e.target.value)}
                      />
                      <Button variant="outline-success" size="lg"
                        onClick={() => this.setNoOfCopies(parseInt(this.state.noOfCopies) + 1)}>+</Button>
                    </InputGroup>
                  </Row>
                  <Row className="next-button">
                    <Col>
                    </Col>
                    <Col xs={6}>
                      <Button variant="success" size="lg" style={{ width: "100%" }}
                        onClick={() => this.slider.slickNext()}>Next</Button>
                    </Col>
                    <Col>
                    </Col>
                  </Row>

                </Row>
              </Container>
            </div>
            <div>
              <Container className="vertical-center" style={{ padding: "0px", overflowY: "scroll", overflowX: "hidden" }}>
                <Row>
                  <Row>
                    <Row>
                      <Row className="info-title">File</Row>
                      <Row>
                        <Col><h3 className="text-on-right">{this.state.documentInfo?.alias ? this.trimmedFileName(this.state.documentInfo.alias) : "Not uploaded"}</h3></Col>
                        <Col xs={2}>
                          <Button variant="outline-success" size="sm" 
                            onClick={() => this.slider.slickGoTo(0)}>change</Button>
                        </Col>
                      </Row>
                      <hr></hr>
                    </Row>
                    <Row>
                      <Row className="info-title">Printer</Row>
                      <Row>
                        <Col><h3 className="text-on-right">{this.state.selectedPrinter?.alias ? this.state.selectedPrinter.alias : "Not Selected"}</h3></Col>
                        <Col xs={2}>
                          <Button variant="outline-success" size="sm" 
                            onClick={() => this.slider.slickGoTo(1)}>change</Button>
                        </Col>
                      </Row>
                      <hr></hr>
                    </Row>
                    <Row>
                      <Row className="info-title">Print Type</Row>
                      <Row>
                        <Col><h3 className="text-on-right">{this.state.selectedPrintType ? this.printTypeMap[this.state.selectedPrintType] : "Not Selected"}</h3></Col>
                        <Col xs={2}>
                          <Button variant="outline-success" size="sm" 
                            onClick={() => this.slider.slickGoTo(2)}>change</Button>
                        </Col>
                      </Row>
                      <hr></hr>
                    </Row>
                    <Row>
                      <Row className="info-title">Number of Copies</Row>
                      <Row>
                        <Col><h3 className="text-on-right">{this.state.noOfCopies}</h3></Col>
                        <Col xs={2}>
                          <Button variant="outline-success" size="sm" 
                            onClick={() => this.slider.slickGoTo(3)}>change</Button>
                        </Col>
                      </Row>
                      <hr></hr>
                    </Row>
                    <Row>
                      <div className="info-title">Total Cost</div>
                      <div className="info-title">{this.state.documentInfo.pageCount} pages x {this.state.noOfCopies} copies x Rs {costPerPage} per page</div>
                      <h3 className="text-on-right">Rs {totalCost}</h3>
                      <hr></hr>
                    </Row>
                  </Row>
                  <Row>
                    <Col>
                    </Col>
                    <Col xs={10}>
                      <Button variant="success" size="lg" style={{ width: "100%" }}
                        onClick={x => this.payAndPrint()}
                        disabled={!(this.state.documentInfo?.alias && this.state.documentInfo?.pageCount && this.state.selectedPrinter.alias && this.state.selectedPrintType && this.state.noOfCopies)}
                      >Pay & Print</Button>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "20px" }}>
                    <div className="issue-message-title">{this.state.documentInfo?.alias ? "" : "Please upload a document"}</div>
                    <div className="issue-message-title">{this.state.selectedPrinter?.alias ? "" : "Please select a printer"}</div>
                    <div className="issue-message-title">{this.state.selectedPrintType ? "" : "Please select a print type"}</div>
                    <div className="issue-message-title">{this.state.noOfCopies ? "" : "Please select valid number of copies"}</div>
                  </Row>
                </Row>
              </Container>
            </div>
            <div>
              <Container className="vertical-center">
                <Row>
                  <Row style={{ height: "50px", textAlign: "center" }}>
                    <h2 style={{ textAlign: "center" }}>
                      {this.state.orderMessage.status === this.PROCESSING ?
                        <div>
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div> : this.state.orderMessage.status === this.PENDING ?
                          "Please complete previous steps." :
                          this.state.orderMessage.status === this.SUCCESS ?
                            "Bamm!!" : "Dang!"}
                    </h2>
                  </Row>
                  <Row>
                    <Row>
                      <h3 style={{ textAlign: "center" }}>{this.state.orderMessage.message}</h3>
                    </Row>
                    {this.state.orderMessage.status === this.ERROR || this.state.orderMessage.status === this.PENDING ?
                      <Row className="next-button">
                        <Button variant="success" onClick={() => this.slider.slickPrev()}>Back</Button>
                      </Row>: <div/>}
                  </Row>
                </Row>
              </Container>
            </div>
          </Slider>
        </Row>
        <Row style={{ paddingTop: "35px" }}>
          <Col>
            <a href="https://www.privacypolicies.com/live/8f75c4b6-1997-4c21-8b69-ce6691d1d73a">Privacy</a>
          </Col>
          <Col>
            <a href="aboutus.html">About us</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="refund.html">Refunds</a>
          </Col>
          <Col>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSet8BKQU8RrBsOtVb2bBGg8msD3eSqAu-EWgS3MZUD1Q0K3Lw/viewform?usp=sf_link">Feedback</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="https://www.privacypolicies.com/live/8f75c4b6-1997-4c21-8b69-ce6691d1d73a">Terms & Conditions</a>
          </Col>
        </Row>
      </div>
    );
  }
}