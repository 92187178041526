
import { docUrl } from './config'
import axios from 'axios'
import {v4 as uuid} from 'uuid'

export const uploadDocument = (userId, file) => {

    let fileName = file.name;
    let clientFileId = uuid();

    return getBase64(file)
        .then((base64string) => {
            let fileType = fileName.split(".")[fileName.split(".").length - 1]

            let body = {
                base64string,
                fileName,
                fileType,
                clientFileId
            }

            let payload = {
                url: `unauth/documents?userId=${userId}`,
                baseURL: docUrl,
                method: 'post',
                data: body
            }

            return axios(payload)
        });
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let complete = reader.result;
            let base64 = complete.substring(complete.indexOf("base64") + 7);
            resolve(base64);
        };
        reader.onerror = function (error) {
            reject('Error: ', error);
        };
    });
}