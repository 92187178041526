import './App.css';
import MainSlider from './MainSlider';

function App() {
  return (
    <MainSlider/>
  );
}

export default App;
